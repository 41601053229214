/* https://google-webfonts-helper.herokuapp.com/fonts/nunito?subsets=latin */



/* nunito-200 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/gfonts/nunito-v20-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-200.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-200.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-300 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/gfonts/nunito-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-regular - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/gfonts/nunito-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-500 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/gfonts/nunito-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-500.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-600 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/gfonts/nunito-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-700 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/gfonts/nunito-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-800 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/gfonts/nunito-v20-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-800.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-900 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/gfonts/nunito-v20-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/nunito-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/nunito-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-900.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/nunito-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/nunito-v20-latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
  }



/* poppins-100 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/gfonts/poppins-v15-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-100.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-200 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/gfonts/poppins-v15-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-200.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-300 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/gfonts/poppins-v15-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/gfonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-500 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/gfonts/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-600 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/gfonts/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/gfonts/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/gfonts/poppins-v15-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-800.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/gfonts/poppins-v15-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/poppins-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/poppins-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-900.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/poppins-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/poppins-v15-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }






  /* montserrat-100 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/gfonts/montserrat-v18-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-100.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-100.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-200 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/gfonts/montserrat-v18-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-200.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-300 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/gfonts/montserrat-v18-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/gfonts/montserrat-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/gfonts/montserrat-v18-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/gfonts/montserrat-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-600.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/gfonts/montserrat-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-800 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/gfonts/montserrat-v18-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-800.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-900 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/gfonts/montserrat-v18-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/montserrat-v18-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/montserrat-v18-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-900.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/montserrat-v18-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/montserrat-v18-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
  }



/* lato-100 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/gfonts/lato-v20-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/lato-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/lato-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-100.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/lato-v20-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-300 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/gfonts/lato-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/lato-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/lato-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/lato-v20-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/gfonts/lato-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/lato-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/lato-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/lato-v20-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/gfonts/lato-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/lato-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/lato-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/lato-v20-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/gfonts/lato-v20-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/lato-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/lato-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-900.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/lato-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/lato-v20-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
  }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/gfonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/gfonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/gfonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/gfonts/open-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/gfonts/open-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/gfonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/gfonts/open-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/gfonts/open-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/gfonts/open-sans-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/gfonts/open-sans-v27-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/gfonts/open-sans-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/gfonts/open-sans-v27-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/gfonts/open-sans-v27-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/gfonts/open-sans-v27-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/gfonts/open-sans-v27-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/gfonts/open-sans-v27-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/fonts/gfonts/open-sans-v27-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/gfonts/open-sans-v27-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* Material Icons */
/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/gfonts/materialicons_v121.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }


/* Muli latin */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/gfonts/Muli_7Auwp_0qiz-afTLGLQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }


@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/gfonts/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  }

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/gfonts/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/gfonts/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/gfonts/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/gfonts/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/gfonts/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/gfonts/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }





/* red-hat-display-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* red-hat-display-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* red-hat-display-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* red-hat-display-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* red-hat-display-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* red-hat-display-800 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* red-hat-display-900 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/gfonts/red-hat-display-v20-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
